















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";

export default Vue.extend({
  data() {
    return {
      users: null,
      search: "",
    };
  },
  computed: {
    searchedUsers() {
      if (!this.users) {
        return [];
      }
      if (!this.search) {
        return this.users;
      }
      return this.users.filter(
        (item) =>
          item.email.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
    },
  },
  async created() {
    const market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    const filters = [];
    if (market.tmdt) {
      const { items } = await await coreApiClient.call("mktPlatforms", "findAll", {
        payload: JSON.stringify({
          limit: -1,
          filters: [
            {
              key: "code",
              operator: "in",
              value: ["s", "lzd", "ttshop"],
            },
          ],
        }),
      });
      filters.push({
        key: "mktPlatformIds",
        operator: "in",
        value: items.map((it) => it._id),
      });
    }
    const { items } = await coreApiClient.call("users", "findAll", {
      payload: JSON.stringify({
        limit: -1,
        filters,
        orderBy: "name",
        orderType: "asc",
        extraParams: {
          marketDetailUsers: true,
        },
      }),
    });
    this.users = items;
  },
});
